<template>
    <div>
        <breadcrumbs :pages="[{ title: 'Home' }, { title: 'xSigma Privacy Policy', isActive: true  }]"></breadcrumbs>
        <b-row class="justify-content-md-center my-5">
            <b-col md="12">
                <h1 class="western" align="CENTER" style="border: none; padding: 0in; line-height: 166%; page-break-inside: auto; page-break-after: auto"><a name="_cju0x6um9c32"></a>
                    <font color="#000000"><font><font size="4" style="font-size: 13pt"><b>xSigma
                        Privacy Policy</b></font></font></font></h1>
                <h1 class="western" style="border: none; padding: 0in; line-height: 166%; page-break-inside: auto; page-break-after: auto"><a name="_cju0x6um9c321"></a>
                    <font color="#000000">‍<font><b>Last Updated:
                    </b></font></font>January 8, 2021</h1>
                <p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                    <font size="3">The xSigma Platform is an open source, decentralized
                        finance (“</font><font size="3"><b>DeFi</b></font><font size="3">”)
                    platform that runs on the Ethereum blockchain (the “</font><font size="3"><b>Platform</b></font><font size="3">”).
                    xSigma recognizes that people who use the Platform value their
                    privacy. This privacy policy (“</font><font size="3"><b>Policy</b></font><font size="3">”)
                    describes how xSigma Corporation (“</font><font size="3"><b>Company</b></font><font size="3">”,
                    “</font><font size="3"><b>we</b></font><font size="3">”, “</font><font size="3"><b>our</b></font><font size="3">”,
                    or “</font><font size="3"><b>us</b></font><font size="3">”) collects,
                    uses, shares, and stores Personal Data (as defined below) of visitors
                    to </font><a href="https://xsigma.fi"><font color="#1155cc"><font size="3"><u>https://xsigma.fi</u></font></font></a><font size="3">
                    and users of our portal at that URL (collectively the “</font><font size="3"><b>Site</b></font><font size="3">”).
                    The Site provides an interface by which you can interact with the
                    Ethereum blockchain, such as to submit transactions to or approve
                    transactions on the Ethereum blockchain</font>. The Site does not
                    itself control any of your Ethereum or other token transactions, all
                    of which remain entirely in your control through your virtual
                    currency wallet. <font size="3">This Policy applies to the Company’s
                    Site, applications, products and services (collectively, “</font><font size="3"><b>Services</b></font><font size="3">”).</font></p>
                <p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                    <font size="3">By using the Services, you accept the terms of this
                        Policy and our DeFi Platform Disclosures (incorporated herein by
                        reference), and consent to our collection, use, disclosure, and
                        retention of your information as described in this Policy.  If you
                        have not done so already, please also review the DeFi Platform
                        Disclosures. The DeFi Platform Disclosures contain provisions that
                        limit our liability to you and require you to resolve any dispute
                        with us on an individual basis and not as part of any class or
                        representative action. IF YOU DO NOT AGREE WITH ANY PART OF THIS
                        POLICY OR OUR DEFI PLATFORM DISCLOSURES, THEN PLEASE DO NOT USE ANY
                        OF THE SERVICES.</font></p>
                <p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                    <font size="3">Please note that this Policy does not apply to
                        information collected through third-party websites or services that
                        you may access through the Services or the Site.</font></p>
                <p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                    <font size="3">If you are visiting this Site from the European Union
                        (“</font><font size="3"><b>EU”</b></font><font size="3">), see our
                    Notice to EU Data Subjects below for our legal bases for processing
                    and transfer of your data.</font></p>
                <p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                    <font size="3">We take our data protection responsibilities with the
                        utmost seriousness and we have designed the Site and Services so that
                        you may navigate and use our website by providing minimal Personal
                        Data.</font></p>
                <p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                    <font size="3">In this Policy, “Personal Data” means any
                        information relating to you as an identified or identifiable natural
                        person; an identifiable natural person is one who can be identified,
                        directly or indirectly, in particular by reference to an identifier
                        such as a name, an online identifier or to one or more factors
                        specific to your physical, physiological, genetic, mental, economic,
                        cultural or social identity.</font></p>
                <p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                    <br><br>
                </p>
                <ol>
                    <li><h3 class="western" align="JUSTIFY"><a name="_sr4in2hi3ryb"></a>WHAT
                        WE COLLECT
                    </h3>
                    </li></ol>
                <p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                    <font size="3">We get information about you in a range of ways.</font></p>
                <p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                    <font size="3"><b>Information You Give Us.</b></font><font size="3">
                    Information we collect from you includes:</font></p>
                <ul>
                    <li><p align="JUSTIFY" style="margin-top: 0.11in; margin-bottom: 0.19in; line-height: 150%">
                        <font size="3">Network information regarding transactions;</font></p>
                    </li><li><p align="JUSTIFY" style="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 150%">
                    <font size="3">Contact information, such as your email address,
                        Telegram, Discord or Twitter contact information;</font></p>
                </li><li><p align="JUSTIFY" style="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 150%">
                    <font size="3">Feedback and correspondence, such as information you
                        provide in your responses to surveys, if and when you participate in
                        market research activities, report a problem with Service, receive
                        customer support or otherwise correspond with us;</font></p>
                </li><li><p align="JUSTIFY" style="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 150%">
                    <font size="3">Usage information, such as information about how you
                        use the Service and interact with us; </font>
                </p>
                </li><li><p align="JUSTIFY" style="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 150%">
                    <font size="3">Marketing information, such as your preferences for
                        receiving marketing;</font></p>
                </li><li><p align="JUSTIFY" style="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 150%">
                    <font size="3">Ethereum wallet addresses that you use; and</font></p>
                </li><li><p align="JUSTIFY" style="margin-top: 0.19in; margin-bottom: 0.32in; line-height: 150%">
                    <font size="3">Ethereum blockchain transactions that you initiate. </font>
                </p>
                </li></ul>
                <p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                    <font size="3"><b>Information We Get From Others.</b></font><font size="3">
                    We may get information about you from other third party sources and
                    we may add this to information we get from your use of the Services.
                    Such information may include social media sites or other third party
                    service providers. These features may collect your Personal Data and
                    track your use of the Site. Your interactions with these features and
                    with third party service providers are governed entirely by the
                    Policy and terms of service of the company providing such
                    functionality. </font>
                </p>
                <p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                    <font size="3"><b>Information Automatically Collected.</b></font><font size="3">
                    We may automatically record certain information about how you use our
                    Site (“</font><font size="3"><b>Log Data</b></font><font size="3">“).
                    Log Data may include information such as IP address, MAC or device
                    IDs, device and browser type, operating system, the pages or features
                    of our Site to which a user browsed and the time spent on those pages
                    or features, the frequency with which the Site is used by a user,
                    search terms, the links on our Site that a user clicked on or used,
                    and other statistics. We use this information to administer the
                    Service and we analyze (and may engage third parties to analyze) this
                    information to improve and enhance the Service by expanding its
                    features and functionality and tailoring it to our users’ needs and
                    preferences.</font></p>
                <p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                    <font size="3">We may use cookies, local storage, or similar
                        technologies to analyze trends, administer the website, track users’
                        movements around the website, and to gather demographic information
                        about our user base as a whole. Users can control the use of cookies
                        and local storage at the individual browser level. </font>
                </p>
                <p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                    <font size="3">We also use Google Analytics to help us offer you an
                        optimized user experience.  You can find more information about
                        Google Analytics’ use of your Personal Data here:
                        <a href="https://policies.google.com/privacy?hl=en">https://policies.google.com/privacy?hl=en</a></font></p>
                <p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                    <font size="3"><b>Information we will never collect</b></font><font size="3">.
                    We will never ask you to share your private keys or wallet seed.
                    Never trust anyone or any site that asks you to enter your private
                    keys or wallet seed.</font></p>
                <ol start="2">
                    <li><p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                        <font size="3"><b>USE OF PERSONAL DATA</b></font></p>
                    </li></ol>
                <p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                    <font size="3"><b>To provide our service</b></font></p>
                <p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                    <font size="3">We will use your Personal Data in the following ways:</font></p>
                <ul>
                    <li><p align="JUSTIFY" style="margin-top: 0.11in; margin-bottom: 0.19in; line-height: 150%">
                        <font size="3">To enable you to access and use the Services;</font></p>
                    </li><li><p align="JUSTIFY" style="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 150%">
                    <font size="3">To improve the Services; </font>
                </p>
                </li><li><p align="JUSTIFY" style="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 150%">
                    <font size="3">To provide and deliver products and services that you
                        may request;</font></p>
                </li><li><p align="JUSTIFY" style="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 150%">
                    <font size="3">To send information, including confirmations, technical
                        notices, updates, security alerts, and support and administrative
                        messages;</font></p>
                </li><li><p align="JUSTIFY" style="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 150%">
                    <font size="3">To screen your IP address against IP addresses of
                        sanction jurisdictions where use of the Services is prohibited,
                        including but not limited to those designated by the U.S. Department
                        of the Treasury Office of Foreign Asset Control (“</font><font size="3"><b>OFAC</b></font><font size="3">”);</font></p>
                </li><li><p align="JUSTIFY" style="margin-top: 0.19in; margin-bottom: 0.32in; line-height: 150%">
                    <font size="3">To block use of the Services in association with
                        Ethereum wallet addresses that are associated with OFAC, other
                        sanctions or suspicious activity.</font></p>
                </li></ul>
                <p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                    <font size="3"><b>To comply with law</b></font></p>
                <p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                    <font size="3">We use your Personal Data as we believe necessary or
                        appropriate to comply with applicable laws, lawful requests and legal
                        process, such as to respond to subpoenas or requests from government
                        authorities.  </font>
                </p>
                <p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                    <font size="3"><b>To communicate with you</b></font></p>
                <p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                    <font size="3">We use your Personal Data to communicate about
                        promotions, upcoming events, and other news about products and
                        services offered by us and our selected partners.</font></p>
                <p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                    <font size="3"><b>To optimize our platform</b></font></p>
                <p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                    <font size="3">In order to optimize your user experience, we may use
                        your Personal Data to operate, maintain, and improve our Services. We
                        may also use your information to respond to your comments and
                        questions regarding the Services, and to provide you and other users
                        with general customer service.</font></p>
                <p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                    <font size="3"><b>With your consent</b></font></p>
                <p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                    <font size="3">We may use or share your Personal Data with your
                        consent, such as when you instruct us to take a specific action with
                        respect to your Personal Data, or you opt into third party marketing
                        communications.</font></p>
                <p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                    <font size="3"><b>For compliance, fraud prevention, and safety</b></font></p>
                <p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                    <font size="3">We may use your Personal Data to protect, investigate,
                        and deter against fraudulent, unauthorized, or illegal activity.</font></p>
                <p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                    <br><br>
                </p>
                <ol start="3">
                    <li><p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                        <font size="3"><b>SHARING OF PERSONAL DATA</b></font></p>
                    </li></ol>
                <p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                    <font size="3">We do not share or sell the Personal Data that you
                        provide us with other organizations without your express consent,
                        except as described in this Policy. We disclose Personal Data to
                        third parties under the following circumstances:</font></p>
                <ul>
                    <li><p align="JUSTIFY" style="margin-top: 0.11in; margin-bottom: 0.19in; line-height: 150%">
                        <font size="3"><b>Affiliates.</b></font><font size="3"> We may disclose
                        your Personal Data to our subsidiaries and corporate affiliates for
                        purposes consistent with this Policy.</font></p>
                    </li></ul>
                <ul>
                    <li><p align="JUSTIFY" style="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 150%">
                        <font size="3"><b>Business Transfers. </b></font><font size="3">We may
                        share Personal Data when we do a business deal, or negotiate a
                        business deal, involving the sale or transfer of all or a part of
                        our business or assets. These deals can include any merger,
                        financing, acquisition, or bankruptcy transaction or proceeding.</font></p>
                    </li><li><p align="JUSTIFY" style="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 150%">
                    <font size="3"><b>Compliance with Laws and Law Enforcement; Protection
                        and Safety. </b></font><font size="3">We may share Personal Data for
                    legal, protection, and safety purposes.</font></p>
                </li></ul>
                <ul>
                    <li><p align="JUSTIFY" style="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 150%">
                        <font size="3">We may share information to comply with laws.</font></p>
                    </li><li><p align="JUSTIFY" style="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 150%">
                    <font size="3">We may share information with law enforcement where
                        required by law or on a voluntary basis in order to report
                        suspicious activity.</font></p>
                </li><li><p align="JUSTIFY" style="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 150%">
                    <font size="3">We may share information to respond to lawful requests
                        and legal processes.</font></p>
                </li><li><p align="JUSTIFY" style="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 150%">
                    <font size="3">We may share information to protect the rights and
                        property of the Company, our agents, customers, and others. This
                        includes enforcing our agreements, policies, and terms of use.</font></p>
                </li><li><p align="JUSTIFY" style="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 150%">
                    <font size="3">We may share information in an emergency. This includes
                        protecting the safety of our employees and agents, our customers, or
                        any person.</font></p>
                </li></ul>
                <ul>
                    <li><p align="JUSTIFY" style="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 150%">
                        <font size="3"><b>Professional Advisors and Service Providers. </b></font><font size="3">We
                        may share information with those who need it to do work for us.
                        These recipients may include third party companies and individuals
                        to administer and provide the Service on our behalf (such as
                        customer support, hosting, email delivery and database management
                        services), as well as lawyers, bankers, auditors, and insurers.</font></p>
                    </li><li><p align="JUSTIFY" style="margin-top: 0.19in; margin-bottom: 0.32in; line-height: 150%">
                    <font size="3"><b>Other. </b></font><font size="3">You may permit us to
                    share your Personal Data with other companies or entities of your
                    choosing. Those uses will be subject to the privacy policies of the
                    recipient entity or entities.</font></p>
                </li></ul>
                <p align="JUSTIFY" style="margin-left: 0.5in; margin-top: 0.11in; margin-bottom: 0.32in; line-height: 150%">
                    <br><br>
                </p>
                <ol start="4">
                    <li><p align="JUSTIFY" style="margin-top: 0.11in; margin-bottom: 0.32in; line-height: 150%">
                        <font size="3"><b>DATA ON THE BLOCKCHAIN</b></font><font size="3"> </font>
                    </p>
                    </li></ol>
                <p align="JUSTIFY" style="margin-top: 0.11in; margin-bottom: 0.32in; line-height: 150%">
                    <font size="3">Blockchain technology is at the core of the Platform.
                        The Platform is a DeFi project on the Ethereum blockchain.
                        Blockchains are usually decentralized and made up of digitally
                        recorded data in a chain of packages called ‘blocks’. The manner
                        in which these blocks are linked is chronological, meaning that the
                        data is very difficult to alter once recorded. Since the ledger may
                        be distributed all over the world, this means there is no single
                        person making decisions or otherwise administering the system, and
                        that there is no centralized place where it is located either. </font>
                </p>
                <p align="JUSTIFY" style="margin-top: 0.11in; margin-bottom: 0.32in; line-height: 150%">
                    <font size="3">By design, a blockchain’s records cannot be changed or
                        deleted once recorded on the blockchain. This may affect your ability
                        to exercise your data rights such as your right to erasure, or your
                        rights to object or restrict processing of your Personal Data. Data
                        on the blockchain cannot be erased and cannot be changed. Although
                        smart contracts may be used to revoke certain access rights, and some
                        content may be made invisible to others, it is not deleted.</font></p>
                <p align="JUSTIFY" style="margin-top: 0.11in; margin-bottom: 0.32in; line-height: 150%">
                    <font size="3">While some data on the blockchain may be anonymized or
                        is not directly identifiable to you, when analyzed together with
                        other public or private data, this information may be used to
                        identify you. </font>
                </p>
                <p align="JUSTIFY" style="margin-top: 0.11in; margin-bottom: 0.32in; line-height: 150%">
                    <font size="3">IF YOU WANT TO ENSURE YOUR PRIVACY RIGHTS ARE NOT
                        AFFECTED IN ANY WAY, YOU SHOULD NOT TRANSACT ON BLOCKCHAINS AS
                        CERTAIN RIGHTS MAY NOT BE FULLY AVAILABLE OR EXERCISABLE BY YOU OR US
                        DUE TO THE TECHNOLOGICAL INFRASTRUCTURE OF THE BLOCKCHAIN. IN
                        PARTICULAR THE BLOCKCHAIN IS AVAILABLE TO THE PUBLIC AND ANY PERSONAL
                        DATA SHARED ON THE BLOCKCHAIN WILL BECOME PUBLICLY AVAILABLE.</font></p>
                <p align="JUSTIFY" style="margin-top: 0.11in; margin-bottom: 0.32in; line-height: 150%">
                    <br><br>
                </p>
                <ol start="5">
                    <li><p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                        <font size="3"><b>HOW INFORMATION IS SECURED AND STORED</b></font></p>
                    </li></ol>
                <p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                    <font size="3">We retain information we collect as long as it is
                        necessary and relevant to fulfill the purposes outlined in this
                        Policy. However, we may retain your Personal Data for a longer period
                        of time where such retention is necessary for compliance with a legal
                        obligation to which we are subject, to prevent fraud, resolve
                        disputes, troubleshoot problems, assist with any investigation,
                        enforce our DeFi Platform Disclosure, and other actions permitted by
                        law. To determine the appropriate retention period for Personal Data,
                        we consider the amount, nature, and sensitivity of the Personal Data,
                        the potential risk of harm from unauthorized use or disclosure of
                        your Personal Data, the purposes for which we process your Personal
                        Data and whether we can achieve those purposes through other means,
                        and the applicable legal requirements.</font></p>
                <p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                    <font size="3">In some circumstances we may anonymize your Personal
                        Data (so that it can no longer be associated with you) in which case
                        we may use this information indefinitely without further notice to
                        you.</font></p>
                <p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                    <font size="3">We employ industry standard security measures designed
                        to protect the security of all information submitted through the
                        Services. However, the security of information transmitted through
                        the internet can never be guaranteed. We are not responsible for any
                        interception or interruption of any communications through the
                        internet or for changes to or losses of data. In order to protect you
                        and your data, we may suspend your use of any of the Services,
                        without notice, pending an investigation, if any breach of security
                        is suspected.</font></p>
                <p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                    <br><br>
                </p>
                <ol start="6">
                    <li><p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                        <font size="3"><b>INFORMATION CHOICES AND CHANGES</b></font></p>
                    </li></ol>
                <p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                    <font size="3"><b>Accessing, Updating, Correcting, and Deleting your
                        Information</b></font></p>
                <p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 139%; widows: 0; orphans: 0">
                    <font size="3">You may access information that you have voluntarily
                        provided to us by contacting us to review, correct, or delete it by
                        sending a request to <a href="mailto:general@xsigma.com">general@xsigma.com</a>. You can request to change contact
                    choices, opt-out of our sharing with others, and update your Personal
                    Data and preferences.</font></p>
                <p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 139%"><font size="3">If
                    you no longer wish to receive marketing communications from us, you
                    can also let us know via email to <a href="mailto:general@xsigma.com">general@xsigma.com</a>. The electronic marketing
                    communications we send to you also contain opt-out mechanisms that
                    allow you to opt-out from receiving those communications, update your
                    contact information or change your preferences at any time. We will
                    honor your choice and refrain from sending you such announcements.
                    You may also opt back in to receive those communications at any time.</font></p>
                <p style="margin-bottom: 0in"><br>
                </p>
                <p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                    <font size="3"><b>Tracking Technologies Generally</b></font></p>
                <p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                    <font size="3">Regular cookies may generally be disabled or removed by
                        tools available as part of most commercial browsers, and in some
                        instances blocked in the future by selecting certain settings. If you
                        disable or block some cookies settings, you may not be able to view
                        or access the Site or Services. For more information, please see the
                        section entitled “Cookies Policy” below.  </font>
                </p>
                <p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                    <br><br>
                </p>
                <ol start="7">
                    <li><p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                        <font size="3"><b>CHANGES TO THIS POLICY. </b></font>
                    </p>
                    </li></ol>
                <p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                    <font size="3">We may change this Policy at any time. We encourage you
                        to periodically review this page for the latest information on our
                        privacy practices. If we make any changes, we will change the last
                        updated date above.</font></p>
                <p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                    <font size="3">Any modifications to this Policy will be effective upon
                        our posting of the new terms and/or upon implementation of the
                        changes to the Site (or as otherwise indicated at the time of
                        posting). In all cases, your continued use of the Site or Services
                        after the posting of any modified Policy indicates your acceptance of
                        the terms of the modified Policy. If you do not agree with the
                        modifications, you must terminate your use of the Site and Services. </font>
                </p>
                <p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                    <br><br>
                </p>
                <ol start="8">
                    <li><p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                        <font size="3"><b>ELIGIBILITY</b></font></p>
                    </li></ol>
                <p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                    <font size="3">The Services are available only to persons 18 years of
                        age or older and are not a Prohibited User (as defined in the xSigma
                        Platform Disclosure).  If we learn that we have received any
                        information directly from a child under the age of 18, we will use
                        that information only to respond directly to that child (or his or
                        her parent or legal guardian) to inform the child that he or she
                        cannot use the Site and subsequently we will delete that information.</font></p>
                <ol start="9">
                    <li><p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                        <font size="3"><b>NOTICE TO CALIFORNIA RESIDENTS</b></font></p>
                    </li></ol>
                <p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                    <font size="3">Under California Civil Code Section 1789.3, California
                        users are entitled to the following consumer rights notice:
                        California residents may reach the Complaint Assistance Unit of the
                        Division of Consumer Services of the California Department of
                        Consumer Affairs by mail at 1625 North Market Blvd., Sacramento, CA
                        95834, or by telephone at (916) 445-1254 or (800) 952-5210.</font></p>
                <ol start="10">
                    <li><p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                        <font size="3"><b>NOTICE TO EU DATA SUBJECTS</b></font></p>
                    </li></ol>
                <p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                    <font size="3"><b>Personal Data</b></font></p>
                <p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                    <font size="3">With respect to EU data subjects, Personal Data, as used
                        in this Policy, is equivalent to “personal data” as defined in
                        the </font><a href="https://gdpr-info.eu/art-4-gdpr/"><font size="3"><b>European
                    Union General Data Protection Regulation</b></font></a><font size="3">
                    (“</font><font size="3"><b>GDPR”</b></font><font size="3">). You can
                    find out more about the GDPR and your rights by accessing the
                    European Commission’s website (https://ec.europa.eu/info/index_en).</font></p>
                <p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                    <font size="3"><b>Sensitive Data</b></font></p>
                <p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                    <font size="3">Some of the information you provide us may constitute
                        sensitive data as defined in the GDPR (also referred to as special
                        categories of Personal Data). </font>
                </p>
                <p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                    <font size="3"><b>Legal Bases for Processing</b></font></p>
                <p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                    <font size="3">We only use your Personal Data as permitted by law. We
                        are required to inform you of the legal bases of our processing of
                        your Personal Data, which are described in this Policy. If you have
                        questions about the legal bases under which we process your Personal
                        Data, contact us at <a href="mailto:general@xsigma.com">general@xsigma.com</a>.</font></p>
                <p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                    <font size="3">Some</font><font size="3"><b> </b></font><font size="3">legal
                    bases under which we process your Personal Data include:</font></p>
                <ol type="a">
                    <li><p align="JUSTIFY" style="margin-bottom: 0.19in; line-height: 137%">
                        <font size="3">To communicate with you;</font></p>
                    </li><li><p align="JUSTIFY" style="margin-bottom: 0.19in; line-height: 137%">
                    <font size="3">To optimize our platform;</font></p>
                </li><li><p align="JUSTIFY" style="margin-bottom: 0.19in; line-height: 137%">
                    <font size="3">For compliance, fraud prevention, and safety; or</font></p>
                </li><li><p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                    <font size="3">To provide our service. </font>
                </p>
                </li></ol>
                <p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                    <font size="3">These processing activities constitute our legitimate
                        interests. We make sure we consider and balance any potential impacts
                        on you (both positive and negative) and your rights before we process
                        your Personal Data for our legitimate interests. We do not use your
                        Personal Data for activities where our interests are overridden by
                        any adverse impact on you (unless we have your consent or are
                        otherwise required or permitted to by law).</font></p>
                <p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                    <font size="3"><b>With your consent</b></font></p>
                <p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                    <font size="3">Where our use of your Personal Data is based upon your
                        consent, you have the right to withdraw it anytime in the manner
                        indicated in the Service or by contacting us at <a href="mailto:general@xsigma.com">general@xsigma.com</a>.</font></p>
                <p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                    <font size="3"><b>Use for New Purposes</b></font></p>
                <p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                    <font size="3">We may use your Personal Data for reasons not described
                        in this Policy, where we are permitted by law to do so and where the
                        reason is compatible with the purpose for which we collected it. If
                        we need to use your Personal Data for an unrelated purpose, we will
                        notify you and explain the applicable legal basis for that use. If we
                        have relied upon your consent for a particular use of your Personal
                        Data, we will seek your consent for any unrelated purpose.</font></p>
                <p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                    <font size="3"><b>Your Rights</b></font></p>
                <p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                    <font size="3">Under the GDPR, you have certain rights regarding your
                        Personal Data. You may ask us to take the following actions in
                        relation to your Personal Data that we hold:</font></p>
                <ul>
                    <li><p align="JUSTIFY" style="margin-top: 0.11in; margin-bottom: 0.19in; line-height: 150%">
                        <font size="3"><b>Opt-out</b></font><font size="3">. Stop sending you
                        direct marketing communications which you have previously consented
                        to receive. We may continue to send you Service-related and other
                        non-marketing communications.</font></p>
                    </li></ul>
                <ul>
                    <li><p align="JUSTIFY" style="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 150%">
                        <font size="3"><b>Access</b></font><font size="3">. Provide you with
                        information about our processing of your Personal Data and give you
                        access to your Personal Data.</font></p>
                    </li><li><p align="JUSTIFY" style="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 150%">
                    <font size="3"><b>Correct</b></font><font size="3">. Update or correct
                    inaccuracies in your Personal Data.</font></p>
                </li><li><p align="JUSTIFY" style="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 150%">
                    <font size="3"><b>Delete</b></font><font size="3">. Delete your Personal
                    Data.</font></p>
                </li><li><p align="JUSTIFY" style="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 150%">
                    <font size="3"><b>Transfer</b></font><font size="3">. Transfer a
                    machine-readable copy of your Personal Data to you or a third party
                    of your choice.</font></p>
                </li><li><p align="JUSTIFY" style="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 150%">
                    <font size="3"><b>Restrict</b></font><font size="3">. Restrict the
                    processing of your Personal Data.</font></p>
                </li><li><p align="JUSTIFY" style="margin-top: 0.19in; margin-bottom: 0.32in; line-height: 150%">
                    <font size="3"><b>Object</b></font><font size="3">. Object to our
                    reliance on our legitimate interests as the basis of our processing
                    of your Personal Data that impacts your rights.</font></p>
                </li></ul>
                <p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                    <font size="3">You can submit these requests by email to <a href="mailto:general@xsigma.com">general@xsigma.com</a>. We may request specific
                    information from you to help us confirm your identity and process
                    your request. Applicable law may require or permit us to decline your
                    request. If we decline your request, we will tell you why, subject to
                    legal restrictions. If you would like to submit a complaint about our
                    use of your Personal Data or response to your requests regarding your
                    Personal Data, you may contact us at <a href="mailto:general@xsigma.com">general@xsigma.com</a> or submit a complaint to the data
                    protection regulator in your jurisdiction. You can find your data
                    protection regulator </font><a href="http://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm"><font size="3"><b>here</b></font></a><font size="3">.</font></p>
                <p align="JUSTIFY" style="margin-bottom: 0in; line-height: 139%">Y<font size="3">ou
                    also have the right to lodge a complaint with the supervisory
                    authority in the country of your habitual residence, place of work,
                    or the place where you allege an infringement of one or more of our
                    rights has taken place, if that is based in the EEA.</font></p>
                <p align="JUSTIFY" style="margin-bottom: 0in; line-height: 139%"><br>
                </p>
                <p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                    <font size="3"><b>Cross-Border Data Transfer</b></font></p>
                <p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                    <font size="3">Please be aware that your Personal Data may be
                        transferred to, processed, and stored in or outside of the EEA. Data
                        protection laws in other countries may be different from those in
                        your country of residence. You consent to the transfer of your
                        information, including Personal Data, outside of the EEA as set forth
                        in this Policy by visiting our Site or using our Service.</font></p>
                <p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                    <font size="3">Whenever we transfer your Personal Data out of the EEA
                        to the U.S. or countries not deemed by the European Commission to
                        provide an adequate level of Personal Data protection, the transfer
                        will be based on a data transfer mechanism recognized by the European
                        Commission as providing adequate protection for Personal Data.</font></p>
                <p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                    <font size="3">Please contact us if you want further information on the
                        specific mechanism used by us when transferring your Personal Data
                        out of the EEA.</font></p>
                <p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                    <br><br>
                </p>
                <ol start="11">
                    <li><p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                        <font size="3"><b>COOKIES POLICY</b></font></p>
                    </li></ol>
                <p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                    <font size="3">We understand that your privacy is important to you and
                        are committed to being transparent about the technologies and methods
                        we use. In the spirit of transparency, this Policy provides detailed
                        information about how and when we use cookies on our Site.</font></p>
                <p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                    <font size="3"><b>Do we use Cookies?</b></font></p>
                <p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                    <font size="3">Yes. We and our marketing partners, affiliates, and
                        analytics or service providers use cookies, web beacons, or pixels
                        and other technologies to ensure everyone who uses the Site has the
                        best possible experience.</font></p>
                <p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                    <font size="3"><b>What is a Cookie?</b></font></p>
                <p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                    <font size="3">A cookie is a small text file that is placed on your
                        hard drive by a web page server. Cookies contain information that can
                        later be read by a web server in the domain that issued the cookie to
                        you. Some of the cookies will only be used if you use certain
                        features or select certain preferences, and some cookies will always
                        be used. Web beacons, tags and scripts may be used in the Site or in
                        emails to help us to deliver cookies, count visits, understand usage
                        and campaign effectiveness and determine whether an email has been
                        opened and acted upon. We may receive reports based on the use of
                        these technologies by our service/analytics providers on an
                        individual and aggregated basis.</font></p>
                <p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                    <font size="3"><b>Why do we use Cookies?</b></font></p>
                <p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                    <font size="3">We generally use cookies for the following purposes:</font></p>
                <ul>
                    <li><p align="JUSTIFY" style="margin-top: 0.11in; margin-bottom: 0.19in; line-height: 150%">
                        <font size="3">To recognize new or past customers.</font></p>
                    </li><li><p align="JUSTIFY" style="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 150%">
                    <font size="3">To improve our Site and to better understand your
                        visits on our platforms and Site.</font></p>
                </li><li><p align="JUSTIFY" style="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 150%">
                    <font size="3">To integrate with third party websites.</font></p>
                </li><li><p align="JUSTIFY" style="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 150%">
                    <font size="3">To serve you with interest-based or targeted
                        advertising.</font></p>
                </li><li><p align="JUSTIFY" style="margin-top: 0.19in; margin-bottom: 0.19in; line-height: 150%">
                    <font size="3">To observe your behaviors and browsing activities over
                        time across multiple websites or other platforms.</font></p>
                </li><li><p align="JUSTIFY" style="margin-top: 0.19in; margin-bottom: 0.32in; line-height: 150%">
                    <font size="3">To better understand the interests of our customers and
                        our website visitors.</font></p>
                </li></ul>
                <p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                    <font size="3">Some cookies are necessary for certain uses of the Site,
                        and without such cookies, we would not be able to provide many
                        services that you need to properly use the Site. These cookies, for
                        example, allow us to operate our Site so you may access it as you
                        have requested and let us recognize that you have an Account (as
                        defined in the DeFi Platform Disclosures) and have logged into that
                        Account to access Site content. They also include cookies that enable
                        us to remember your previous actions within the same browsing session
                        and secure our Sites.  </font>
                </p>
                <p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                    <font size="3">We also use functional cookies and cookies from third
                        parties for analysis and marketing purposes. Functional cookies
                        enable certain parts of the site to work properly and your user
                        preferences to remain known.  Analysis cookies, among other things,
                        collect information on how visitors use our Site, the content and
                        products that users view most frequently, and the effectiveness of
                        our third party advertising. Advertising cookies assist in delivering
                        ads to relevant audiences and having our ads appear at the top of
                        search results. Cookies are either “session” cookies which are
                        deleted when you end your browser session, or “persistent,” which
                        remain until their deletion by you (discussed below) or the party who
                        served the cookie.  </font>
                </p>
                <p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                    <br><br>
                </p>
                <ol start="12">
                    <li><p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                        <font size="3"><b>QUESTIONS, COMMENTS, FEEDBACK</b></font></p>
                    </li></ol>
                <p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                    <font size="3">You may access and verify your Personal Data held by Us
                        by submitting an email request to <a href="mailto:general@xsigma.com">general@xsigma.com</a>. </font>
                </p>
                <p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                    <font size="3">You will not have to pay a fee to access your Personal
                        Data or to exercise any of your other rights. We may charge a
                        reasonable fee if your request is clearly unfounded, repetitive or
                        excessive. Alternatively, we may refuse to comply with your request
                        in these circumstances.</font></p>
                <p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                    <font size="3">We may need to request specific information from you to
                        help us confirm your identity and ensure your right to access your
                        Personal Data (or to exercise any of your other rights). This is a
                        security measure to ensure that Personal Data is not disclosed to any
                        person who has no right to receive it. We may also contact you to ask
                        you for further information in relation to your request to speed up
                        our response.</font></p>
                <p align="JUSTIFY" style="margin-bottom: 0.21in; line-height: 137%">
                    <font size="3">We try to respond to all legitimate requests within one
                        month. Occasionally it may take us longer than a month if your
                        request is particularly complex or you have made a number of
                        requests. In this case, we will notify you and keep you updated.</font></p>
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
    name: "Policy",
};
</script>

<style scoped lang="scss">
@import "src/assets/variables";
h2 {
    color: $grey6;
}
@page {
    margin-left: 1in;
    margin-right: 1in;
    margin-top: 1in;
    margin-bottom: 0.5in
}

P {
    margin-bottom: 0.08in;
    font-size: 14px!important;
}

H1 {
    margin-top: 0in;
    margin-bottom: 0.21in;

    direction: ltr;
    line-height: 137%;
    page-break-inside: avoid;
    widows: 2;
    orphans: 2
}

H1.western {
    font-family: "Arial", serif;
    font-size: 12pt
}

H1.cjk {
    font-size: 12pt
}

H1.ctl {
    font-size: 12pt;
    font-weight: normal
}

H3 {
    margin-top: 0in;
    margin-bottom: 0.21in;
    direction: ltr;
    line-height: 137%;
    page-break-inside: avoid;
    widows: 2;
    orphans: 2
}

H3.western {
    font-family: "Arial", serif;
    font-size: 12pt
}

H3.cjk {
    font-family: "Arial";
    font-size: 12pt
}

H3.ctl {
    font-family: "Arial";
    font-size: 12pt;
    font-weight: normal
}

A:link {
    so-language: zxx
}
</style>
